import { Navigate } from '@remix-run/react';
import { type PropsWithChildren, useState } from 'react';

import logo from '../../assets/img/logo-text.svg';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { getAgentInfo, isMobile } from '../../utils/user-agent';
import { Modal } from '../common/Modal';

function SafariPrompt(): JSX.Element {
  return (
    <Modal>
      <div className='w-176 h-96 flex flex-col bg-modal rounded-xl text-white relative pt-12 pb-10 items-center'>
        <div className='w-full h-full flex flex-col justify-between items-center'>
          <img src={logo} className='w-63 absolute -top-26' alt='logo' />

          <p className='my-8 font-cairo text-3xl'>Welcome to Luna Park!</p>
          <div className='w-108 h-30 mb-8 font-bold text-base flex flex-col justify-between items-center'>
            <p className='text-center'>
              Unfortunately, our video and audio streaming services are not yet
              supported on Safari.
            </p>
            <p className='text-center'>
              To access Luna Park, please use the latest version of{' '}
              <a
                href='https://google.com/chrome'
                target='_blank'
                rel='noopener noreferrer'
                className='text-primary'
              >
                Google Chrome
              </a>
              .
            </p>
          </div>
          <div className='text-3xs text-secondary'>
            Click “Google Chrome” above to download the latest version.
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function GlobalBlockedAccess({
  blockSafari = !getFeatureQueryParam('safari'),
  children,
}: PropsWithChildren<{
  blockSafari?: boolean;
}>): JSX.Element {
  const [showSafariPrompt] = useState(
    blockSafari && getAgentInfo().browser.isWebkit
  );

  if (isMobile())
    return (
      <Navigate
        replace
        to={{
          pathname: '/mobile-access-denied',
          search: window.location.search,
        }}
      />
    );
  if (showSafariPrompt) return <SafariPrompt />;
  return <>{children}</>;
}
